// Font mixin
@mixin font-gotham($fontweight: false, $fontsize: false, $lineheight: false) {
    font-family: "Gotham A", "Gotham B", sans-serif;

    @if $fontweight {
        font-weight: $fontweight;
    }

    @if $fontsize {
        font-size: $fontsize;
    }

    @if $lineheight {
        line-height: $lineheight;
    }
}