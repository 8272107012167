// Element styles
// WP admin bar override
// TODO :: remove
html.wpadminbar-override {
    margin-top: 0 !important;
}

body {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialaised;
    -moz-osx-font-smoothing: grayscale;
    @include font-gotham(400, 14px, 20px);
}

a {
    color: $orient;
    text-decoration: none;
    transition: color 0.2s;
}

h1 {
    letter-spacing: 0.5px;
    @include font-gotham(500, 65px, 75px);

    @include media($medium-screen) {
        font-size: 50px;
        line-height: 60px;
    }

    @include media($small-screen) {
        font-size: 2.75em;
        line-height: 1.25em;
    }
}

h2 {
    margin-bottom: 15px;
    letter-spacing: 1.5px;
    @include font-gotham(500, 40px, 48px);

    @include media($medium-screen) {
        font-size: 32px;
        line-height: 39px;
    }

    @include media($small-screen) {
        font-size: 1.65em;
        line-height: 1.4em;
        letter-spacing: 0.04em;
    }
}

h3 {
    letter-spacing: 0.5px;
    @include font-gotham(500, 22px, 25px);
}

h4 {
    margin-bottom: 10px;
    color: $grey-222;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    @include font-gotham(300, 13px, 17px);

    .h4__inner-text {
        position: relative;
        z-index: $zIndex-2--h4-text;
    }

    &:after {
        position: relative;
        top: -10px;
        left: -15px;
        z-index: $zIndex-1--h4-bar;
        display: block;
        height: 15px;
        content: ' ';
        opacity: .5;

        @include media($small-screen) {
            display: none;
        }
    }

    @include media($small-screen) {
        margin-bottom: 1.25em;
        font-size: 0.9em;
        line-height: 1.2em;
    }
}

h4.h4--mystic {
    color: $grey-222;

    &:after {
        background: $mystic;
    }
}

h4.h4--steel-blue {
    color: $white;

    &:after {
        background: $orient;
    }
}

h5 {
    letter-spacing: 0.5px;
    @include font-gotham(400, 20px, 25px);
}

h6 {
    @include font-gotham(400, 16px, 25px);
}

p {
    margin-bottom: 10px;
    @include font-gotham(300, 16px, 25px);

    @include media ($small-screen) {
        font-size: 0.9em;
        line-height: 1.6em;
    }
}

section {
    position: relative;
    padding: 60px 0;
    border-bottom: 1px solid $black-10;

    @include media($small-screen) {
        padding: 3em 0;
    }

    @include media($xsmall-screen) {
        padding: 2.5em 0;
    }
}

table {
    border: 1px solid $black-10;

    td {
        border-right: 1px solid $black-10;
    }

    td:last-child {
        border-right: none;
    }
}

input,
input[type="text"] {
    display: inline-block;
    padding: 8px 12px;
    border: none;
    @include font-gotham(300, 13px, 18px);

    &:focus {
        outline: none;
    }
}

input[type="submit"] {
    display: block;
    margin: 0 auto;
    padding: 8px 12px;
    background: $neptune;
    color: $white;
    border: 2px solid $steel-blue;
    border-radius: 2px;
    appearance: none;
    outline: none;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    cursor: pointer;
    @include font-gotham(400, 14px, 18px);
    transition: background 0.3s, border 0.3s;


    &:hover {
        background: $steel-blue;
    }
}

textarea {
    padding: 8px 12px;
    border: none;
    @include font-gotham(300, 13px, 18px);
    resize: none;

    &:focus {
        outline: none;
    }
}

select {
    display: block;
    padding: 10px 12px;
    appearance: none;
    outline: none;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    letter-spacing: 1.5px;
    cursor: pointer;
    @include font-gotham(400, 15px, 20px);
    text-indent: 0.01px;
    text-overflow: '';

     @include media ($small-screen) {
        width: 100%;
    }

}

button {
    background: none;
    border: none;
    appearance: none;
    color: $steel-blue;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    @include font-gotham(400, 13px, 17px);
    transition: color 0.2s;

    &:hover {
        color: $sunset-orange;
    }

    &:focus {
        outline: none;
    }
}

.select-wrap {
    position: relative;
    padding: 0;
}

.select-wrap::after {
    position: absolute;
    top: 11px;
    right: 10px;
    display: inline-block;
    width: 10px;
    height: 10px;
    pointer-events: none;
    font-family: FontAwesome;
    font-size: 16px;
    content: '\f107';
}

.fa.fa-angle-up,
.fa.fa-angle-down {
    font-size: 16px;
    line-height: inherit;
}

// Utility classes
body.no-scroll {
    overflow-y: hidden;
}

body.mobile-menu-open {

    .header {
        .container > .icon--hamburger {
            opacity: 0;
        }
    }

    .menu-wrap {
        z-index: $zIndex-8;
    }

}

body.logged-in.admin-bar {
    #wpadminbar {
        display: none;
    }
}

.clearfix {
    clear: both;
}

.weight-book {
    @include font-gotham(400);
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}


// Container
.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1060px;
    box-sizing: border-box;

    @include media($large-screen) {
        max-width: 800px;
    }

    @include media($medium-screen) {
        max-width: 720px;
    }

    @include media($small-screen) {
        max-width: 100%;
        padding: 0 1.5em;
    }
}

.container.container--small {
    max-width: 960px;

    @include media($large-screen) {
        max-width: 800px;
    }

    @include media($medium-screen) {
        max-width: 720px;
    }

    @include media($small-screen) {
        max-width: 100%;
        padding: 0 2em;
    }
}

// Divider
.divider {
    display: block;
    width: 50px;
    height: 1px;
    background: $grey-222;

    &.divider--large {
        width: 100px;
        height: 2px;
    }
}

// Standard link
.link,
.link__standard,
.link__action {
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.6;
    }
}

.link__standard {

    .link-text {
        text-transform: uppercase;
        letter-spacing: 1.5px;
        @include font-gotham(400, 14px, 17px);
    }
}

.link__standard.link--sunset-orange {
    color: $sunset-orange;
}

// Action link
.link__action {
    display: inline-block;
    color: $orient;

    .link-text {
        text-transform: uppercase;
        letter-spacing: 1.5px;
        @include font-gotham(400, 14px, 17px);
    }

    .fa {
        position: relative;
        left: 2px;
        font-size: 16px;
    }
}

.link__action--white {
    color: $white;
}

// Button link
.link__btn {
    display: inline-block;
    padding: 12px 16px;
    background: transparent;
    border-width: 1px;
    border-style: solid;
    transition: background 0.2s, color 0.2s;
}

.link__btn.link--sunset-orange {
    border-color: $sunset-orange;
    color: $sunset-orange;

    &:hover {
        background: $sunset-orange;
        color: $white;
    }
}

// Buttons
.btn {
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    border: none;
    @include appearance(none);
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover,
    &:active {

        .btn__inner-fill:after {
            width: 100%;
        }

    }

    .btn__inner-fill {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $zIndex-2--btn-fill;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid transparent;
        border-radius: 2px;
        transition: border-color 0.3s;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            content: '';
            transition: width 0.3s;
        }

        &:hover,
        &:active {
            transition: border-color 0s;
        }
    }

    .btn__text {
        position: relative;
        z-index: $zIndex-3--btn-text;
        letter-spacing: 1.5px;
        text-align: center;
        text-transform: uppercase;
        @include font-gotham(400, 14px, 18px);
        transition: color 0.3s;
    }

    .btn__shadow {
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: $zIndex-1--btn-shadow;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid transparent;
        border-radius: 2px;
        transition: background 0.3s, border-color 0.3s;
    }

    // Neptune button modifier
    &.btn--neptune {

        .btn__inner-fill {
            background: $neptune;
            border-color: $neptune;
        }

        .btn__text {
            color: $white;
        }

        .btn__shadow {
            background: $steel-blue;
            border-color: $steel-blue;
        }

        &:hover,
        &:active {

            .btn__inner-fill {
                border-color: $steel-blue;

                &:after {
                    background: $steel-blue;
                }
            }

            .btn__shadow {
                background: $neptune;
                border-color: $neptune;
            }

        }
    }

    // Blue button modifier
    &.btn--blue {

        .btn__inner-fill {
            background: $steel-blue;
            border-color: $steel-blue;
        }

        .btn__text {
            color: $white;
        }

        .btn__shadow {
            background: $orient;
            border-color: $orient;
        }

        &:hover,
        &:active {

            .btn__inner-fill {
                border-color: $orient;

                &:after {
                    background: $orient;
                }
            }

            .btn__shadow {
                background: $steel-blue;
                border-color: $steel-blue;
            }

        }
    }

    // Dark blue button modifier
    &.btn--dark-blue {

        .btn__inner-fill {
            background: $orient;
            border-color: $orient;
        }

        .btn__text {
            color: $white;
        }

        .btn__shadow {
            background: $steel-blue;
            border-color: $steel-blue;
        }

        &:hover,
        &:active {

            .btn__text {
                color: $orient;
            }

            .btn__inner-fill {

                &:after {
                    background: $white;
                }

            }

            .btn__shadow {
                background: $white;
            }

        }
    }

    // White button modifier
    &.btn--white {
        .btn__inner-fill {
            background: $white;
            border-color: $orient;
        }

        .btn__text {
            color: $orient;
        }

        .btn__shadow {
            background: transparent;
            border-color: $steel-blue;
        }

        &:hover,
        &:active {

            .btn__text {
                color: $white;
            }

            .btn__inner-fill {
                border-color: $steel-blue;

                &:after {
                    background: $steel-blue;
                }
            }

            .btn__shadow {
                background: $orient;
                border-color: $orient;
            }

        }
    }
}

// Top navigation
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zIndex-5--header;
    width: 100%;
    height: 68px;
    background: $white-99;
    box-shadow: 0 0 2px 2px $nav-dropshadow;
    @include font-gotham(400, 14px, 18px);

    @include media($small-screen) {
        padding: 12px 0;
        backface-visibility: hidden;
    }
}

.header.is-fixed {

}

.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 20px;
    max-width: 100%;

    @include media($small-screen) {
        padding: 0 20px;
    }
}

.header h1.site-title {
    float: left;
    font-size: 20px;
    line-height: 22px;
    box-sizing: border-box;

    @include media($small-screen) {
        position: relative;
        width: auto;
        padding-top: 0;
    }

    & > a.logo {
        display: block;
        max-width: 180px;
        color: $grey-333;
        transition: color 0.2s;

        .logo__top {
            font-weight: 500;
        }

        .logo__bottom {
            font-weight: 400;
        }
    }

    & > a.logo:hover {
        color: $sunset-orange;
    }
}

.header .container > .icon--hamburger {
    display: none;
    cursor: pointer;

    .icon__bar,
    .icon__bar:before,
    .icon__bar:after {
        color: $grey-222;
    }

    @include media($small-screen) {
        display: block;
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.menu-wrap {

    @include media($small-screen) {
        position: fixed;
        top: -100%;
        left: 0;
        z-index: $zIndex-2;
        display: block;
        overflow-y: scroll;
        padding: 0;
        background: rgba($white, 0.98);
        opacity: 0;
        width: 100%;
        height: 100vh;
        transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
    }

    .locations__mobile-menu {
        display: none;
        text-align: center;

        @include media($small-screen) {
            display: block;
        }
    }

    .icon--close {
        position: absolute;
        top: -100%;
        right: 10px;
        z-index: $zIndex-8;
        padding: 1em;
        transition: top 0.5s ease-in-out;
    }
}

.menu-wrap.is-open {
    top: 0;
    opacity: 1;

    .icon--close {
        top: 10px;

        &:before,
        &:after {
            opacity: 1;
        }
    }
}

// WP menu
.menu {
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px 0 0;
    color: $grey-333;
    text-align: right;

    @include media($small-screen) {
        display: block;
        width: 100%;
        padding-right: 0;
        padding-top: 5em;
        text-align: center;
    }

    li:first-child {

        @include media($small-screen) {
            border-top: 1px solid $black-10;
        }       
    }
}

// Regular menu item
.menu-item {
    position: relative;
    display: inline-block;
    padding: 20px 1.5em;

    @include media($medium-screen) {
        padding-left: 1.25em;
        padding-right: 1.25em;
    }

    @include media($small-screen) {
        padding: 0;
        border-bottom: 1px solid $black-10;
        display: block;
    }

    & > a {
        display: block;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        @include font-gotham(400, 13px, 18px);
        transition: color 0.2s;

        @include media($small-screen) {
            display: block;
            padding: 15px 0;
            color: $grey-222;
            transition: background 0.2s, color 0.2s;
        }

    }

    .fa {
        position: relative;
        left: 5px;
        display: none;
        transition: transform 0.2s, color 0.2s;

        @include media($small-screen) {
            position: absolute;
            top: 15px;
            left: inherit;
            right: 1.5em;
            font-size: 20px;
        }
    }

}

// Menu item with submenus
.menu-item.menu-item-has-children .fa {
    display: inline-block;
}

// Menu item hover and selected
.menu-item > a:hover,
.menu-item.is-open > a,
.menu-item.is-active > a {
    color: $sunset-orange;

    .fa {
        color: $sunset-orange;
    }

    @include media($small-screen) {
        background: $white;
    }
}

// Menu item selected
.menu-item.is-open {

    .fa {
        @include transform(rotate(-180deg));
    }

    ul.sub-menu {
        max-height: 1000px;
        opacity: 1;
    }
}

ul.sub-menu {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: $zIndex-6--submenu;
    overflow: hidden;
    max-height: 0;
    background: $sunset-orange;
    text-align: left;
    opacity: 0;
    transition: max-height 0.3s, opacity 0.3s;

    @include media($small-screen) {
        position: relative;
        top: 0;
        right: inherit;
        z-index: auto;
        width: 100%;
        text-align: center;
        transition: max-height 0.5s;
    }

    &:before {
        position: absolute;
        top: -5px;
        right: 17px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $sunset-orange;
        content: '';

        @include media($small-screen) {
            display: none;
        }
    }

    .sub-menu-item {
        display: block;
        margin: 5px;
        padding: 10px 12px;
        transition: background 0.2s;
        cursor: pointer;

        @include media($small-screen) {
            padding: 0;
            margin: 0;

            & > .menu-link {
                padding: 0;
            }

            & > .menu-link.sub-menu-link {
                padding: 15px 0;
            }
        }

        .sub-menu-link {
            color: $white;
            white-space: nowrap;
        }

        &:hover {
            background: $white-10;
        }
    }


    .sub-menu-item .fa {
        display: none;
    }

    .sub-menu-item > a:hover {
        
        @include media($small-screen) {
            background: transparent;
        }

    }

}

// Hamburger icon

// May need to tighten this up
span.icon--hamburger {
    position: relative;
    z-index: $zIndex-7;
    float: left;
    display: inline-block;
    width: 20px;
    height: 38px;
    margin-right: 10px;

    .icon__bar,
    .icon__bar:before,
    .icon__bar:after {
        position: absolute;
        left: 0;
        width: 20px;
        height: 2px;
        background: $grey-222;
    }

    .icon__bar {
        top: 17px;
    }

    .icon__bar:before {
        top: -6px;
        width: 20px;
        content: '';
    }

    .icon__bar:after {
        top: 6px;
        width: 20px;
        content: '';
    }

}

// Close icon
.icon--close {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;

    &:before,
    &:after {
        position: absolute;
        top: 25px;
        left: 10px;
        width: 30px;
        height: 2px;
        background: $grey-222;
        transition: opacity 0.2s;
        content: '';
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }


    &:hover {

        &:before,
        &:after {
            opacity: 0.7;
        }

    }

}

// Locations nav

.locations__menu {
    display: table-cell;
    overflow: hidden;
    padding: 15px;
    width: 200px;
    background: $orient;
    color: $white;
    box-sizing: border-box;
    cursor: pointer;
    transition: background 0.2s;

    @include media($medium-screen) {
        width: 50px;
    }

    @include media($small-screen) {
        display: none;
    }

    &:hover {
        background: rgba($orient, 0.7);
    }

    h3 {
        float: left;
        display: inline-block;
        padding-top: 9px;
        margin-left: 5px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        @include font-gotham(400, 13px, 18px);

        @include media($medium-screen) {
            display: none;
        }
    }

    span.icon--hamburger {
        display: block;

        .icon__bar,
        .icon__bar:before,
        .icon__bar:after {
            background: $white;
        }

    }
}

// Takeover
.takeover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zIndex-8--locations-takeover;
    overflow-y: scroll;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.98);
    color: $grey-222;
    text-align: center;
    opacity: 0;
    @include transform(scale(0.9));
    transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;

    .icon--close {
        position: fixed;
        top: 10px;
        right: 20px;
        display: block;
        padding: 1em;
    }

    h4.h4--mystic {
        color: $white;

        &:after {
            left: 0;
            margin: 0 auto;
        }
    }


}

.takeover.is-open {
    visibility: visible;
    opacity: 1;
    @include transform(scale(1));
    transition: transform 0.4s, opacity 0.4s;
}


// Locations takeover
.locations__takeover-wrap {
    padding: 60px 0;
}

.locations__takeover {
    padding: 40px;

    h3 {
        margin-bottom: 40px;
        color: $steel-blue;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }

    .locations__item {
        margin: 1.25em 0;

        & > a {
            display: inline-block;
            padding: 0;
            color: $grey-222;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            transition: color 0.2s;
        }

        & > a:hover {
            color: $sunset-orange;
        }
    }

    .locations__item:last-child {

        &:before {
            display: block;
            width: 50px;
            height: 1px;
            margin: 1.75em auto;
            background: $grey-222;
            content: '';
        }

    }

}

h2.region__title {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 40px;
    letter-spacing: 1px;
    white-space: nowrap;
    @include font-gotham(500, 7em, normal);

    @include media($medium-screen) {
        font-size: 6em;
    }

    @include media($small-screen) {
        left: inherit;
        min-width: inherit;
        justify-content: flex-start;
        font-size: 3em;
    }
    span {
        padding: 0 .3em;
        flex-shrink: 0;

        @include media($small-screen) {
            text-align: left;
        }
    }

    &:before {
        flex-shrink: 0;
        color: $sunset-orange;
        opacity: .7;
        content: attr(data-text);

        @include media($small-screen) {
            display: none;
        }
    }

    &:after {
        flex-shrink: 0;
        color: $sunset-orange;
        opacity: .7;
        content: attr(data-text);

    }
}

// Contact
.page__contact {

    text-align: center;

    .container {
        max-width: 920px;
        padding: 0 20px;

        @include media($small-screen) {
            max-width: 100%;
            padding: 0 1.5em;
        }
    }

    h5 {
        margin-bottom: 1.5em;
    }


    .contact__info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 3em auto 0;

        .contact__info-wrap {
            margin: 0 2em;
        }

        h5 {
            margin-bottom: .75em;
        }

        a {
            color: $sunset-orange;
            transition: opacity 0.2s;
        }

        a:hover {
            opacity: 0.7;
        }

        p {
            margin-bottom: .4em;
        }
    }

}

.contact__form  {

    li {
        list-style-type: none;
    }

    .flex__row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-bottom: 2em;

        @include media($small-screen) {
            flex-flow: row wrap;
            margin-bottom: 0;
        }
    }

    .input__wrap,
    .wpcf7-form-control-wrap, {
        flex-grow: 1;
        margin-right: 2em;

        @include media($small-screen) {
            width: 100%;
            margin: 0 0 1.5em;
        }

        &:last-child {
            margin-right: 0;
        }

        input[type="text"],
        input[type="email"] {
            width: 100%;
            border: 1px solid $gray-chateau;
        }

        textarea {
            width: 100%;
            height: 200px;
            background: $white;
            border: 1px solid $gray-chateau;
        }
    }

}

// Content
.content {
    padding-top: 68px;
}

// Vertical nav
.vertical-nav {
    position: fixed;
    top: 50%;
    left: 20px;
    z-index: $zIndex-4--scroll-nav;
    width: 100px;
    @include transform(translateY(-50%));

    &.is-hidden {

    }

    @include media($medium-screen) {
        display: none;
    }
}

.vertical-nav__item {
    margin: 0 0 10px;
}

.vertical-nav__link {
    padding: .4em 0;

    span {
        display: inline-block;
        @include transform(scale(0.6));
    }

    .vertical-nav__dot {
        position: relative;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 2px solid $sunset-orange;
        transition: transform 0.2s, background-color 0.5s;
        -webkit-backface-visibility: hidden;
    }

    .vertical-nav__link-text {
        position: relative;
        top: -3px;
        color: $grey-222;
        opacity: 0;
        @include font-gotham(400, 13px, 18px);
        transition: transform 0.2s, opacity 0.2s, color 0.2s;
        @include transform-origin(left center);
    }
}

.vertical-nav__link:hover {

    span {
        @include transform(scale(1));
    }

    .vertical-nav__link-text {
        opacity: 1;
    }

}

.vertical-nav__link.is-selected {

    .vertical-nav__dot {
        background: $sunset-orange;
    }

}

.vertical-nav__link.is-dark {

    .vertical-nav__link-text {
        color: $white;
    }

}

// Contact nav link
.vertical-nav__item--contact {

    .vertical-nav__dot {
        left: -2px;
        width: 21px;
        height: 17px;
        margin-right: 0;
        background: url('../img/icon-contact.png') no-repeat;
        border: none;
        border-radius: none;
        @include transform(scale(1));
    }
}

// Beam me up!
.beam-me-up__wrap {
    position: absolute;
    right: 20px;
    bottom: 40px;
    color: $grey-222;
    cursor: pointer;

    @include media($medium-screen) {
        display: none;
    }

    span {
        display: inline-block;
        @include transform(scale(0.7));
    }

    span.fa {
        font-size: 36px;
        padding-right: 6px;
        transition: transform 0.2s;
    }

    span.beam-me-up__text {
        position: relative;
        top: -3px;
        opacity: 0;
        @include font-gotham(400, 13px, 18px);
        @include transform-origin(right center);
        transition: transform 0.2s, opacity 0.2s;
    }

    &:hover {

        span {
            opacity: 1;
            @include transform(scale(1));
        }

    }
}

// Footer
.footer {
    display: block;
    width: 100%;
}

.footer a {
    transition: opacity 0.2s;
}

.footer a:hover {
    opacity: 0.6;
}

.footer__top {
    padding: 40px 0 30px;
}

.footer__bottom {
    overflow: hidden;
    padding: 20px 0;
    background: $orient;
    color: $white;
    font-weight: 300;

    .container > span {
        float: left;
        width: 33.3333333%;

        @include media($small-screen) {
            display: block;
            width: 100%;
            padding: 8px 10px;
            text-align: center;
        }
    }
}

.footer__aside {
    padding: 0 0 20px;
    width: 100%;
    text-align: center;

    p {
        letter-spacing: 0.5px;
        font-size: 20px;
        line-height: 25px;

        @include media($small-screen) {
            font-size: 0.9em;
            line-height: 1.2em;
        }
    }

    .contact-us {
        padding-top: .5em;
    }

    .footer__label {
        display: block;
        letter-spacing: 0.5px;
        @include font-gotham(500, 20px, 25px);
    }

    input[type="text"] {
        margin-top: 5px;
        background: $mystic;
        width: 320px;
    }

    .link__action {
        margin-left: 15px;
    }
}

.footer .table--contact {
    width: 100%;

    tr {
        @include media($small-screen) {
            display: block;
        }
    }

    td {
        padding: 5px 0;
        text-align: center;

        @include media($small-screen) {
            display: block;
            width: 100%;
            border-bottom: 1px solid $black-10;
            border-right: none;
        }
    }

    td:last-child {

         @include media($small-screen) {
            border-bottom: none;
        }

    }
}


// Intro sections on static pages
.section__intro {
    background: url('../img/bg-dot.png') repeat;
    background-position: -4px -4px;
    text-align: center;

    h1 {
        margin-bottom: .25em;
    }

    & > .container p:first-child {
        margin-bottom: 0.5em;
    }

    p {
        @include font-gotham(400, 30px, 45px);

        @include media($medium-screen) {
            font-size: 24px;
            line-height: 36px;
        }

        @include media($small-screen) {
            font-size: 1.5em;
            line-height: 1.5em;
        }
    }

}


// Send us an email
.send-email__wrap {
    display: block;
    margin-top: 40px;
    text-align: center;

    .link--email {
        display: inline-block;
        color: $sunset-orange;
        @include font-gotham(400, 16px, 25px);
    }

}


// 404 page
section.page__404 {
    text-align: center;
}
