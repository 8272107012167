// Mono colors
$white: #fff;
$black: #000;
$white-10: rgba(255, 255, 255, 0.1);
$white-95: rgba(255, 255, 255, 0.95);
$white-99: rgba(255, 255, 255, 0.99);
$black-10: rgba(0, 0, 0, 0.1);


$grey-222: #222;
$grey-333: #333;
$dusty-gray: #979797;
$grey-placeholder: #eee;
$off-white: #fafafa;
$gray-chateau: #9BA0A3;

$nav-dropshadow: rgba(200, 200, 200, 0.3);


// Regular colors
$orient: #00577b;
$steel-blue: #3f819c;
$neptune: #7fabbd;
$sunset-orange: #ff494f;
$mystic: #edf2f4;

// Media query vars
$large-screen: max-width 1280px;
$medium-screen: max-width 1024px;
$small-screen: max-width 768px;
$xsmall-screen: max-width 420px;

// Z-index
$zIndex-0: 0;
$zIndex-1: 10;
$zIndex-2: 20;
$zIndex-3: 30;
$zIndex-4: 40;
$zIndex-5: 50;
$zIndex-6: 60;
$zIndex-7: 70;
$zIndex-8: 80;
$zIndex-9: 90;


// Header
$zIndex-5--header: $zIndex-5;
$zIndex-6--submenu: $zIndex-6;

// H4
$zIndex-1--h4-bar: $zIndex-1;
$zIndex-2--h4-text: $zIndex-2;

// Btn
$zIndex-1--btn-shadow: $zIndex-1;
$zIndex-2--btn-fill: $zIndex-2;
$zIndex-3--btn-text: $zIndex-3;

// Takeover
$zIndex-8--locations-takeover: $zIndex-8;

// Scroll nav
$zIndex-4--scroll-nav: $zIndex-4;

// Section positioning
$zIndex-1--section: $zIndex-1;

// Home
$zIndex-1--home-section: $zIndex-1;
$zIndex-2--hero-content: $zIndex-2;

// Home map
$zIndex-0--map-bg-wrap: $zIndex-0;
$zIndex-1--map-bg: $zIndex-1;
$zIndex-2--map-content: $zIndex-2;
$zIndex-3--map-svg: $zIndex-3;
$zIndex-4--map-pin: $zIndex-4;
$zIndex-5--map-pin-arrow: $zIndex-5;

// Region
$zIndex-2--region-info: $zIndex-2;
$zIndex-3--region-info-active: $zIndex-3;
$zIndex-3--region-info-btn: $zIndex-3;

// About section
$zIndex-2--fingerprint-bg: $zIndex-2;
