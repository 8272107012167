html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html.wpadminbar-override {
  margin-top: 0 !important;
}

body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialaised;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

a {
  color: #00577b;
  text-decoration: none;
  transition: color 0.2s;
}

h1 {
  letter-spacing: 0.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 500;
  font-size: 65px;
  line-height: 75px;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.75em;
    line-height: 1.25em;
  }
}

h2 {
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
}

@media screen and (max-width: 1024px) {
  h2 {
    font-size: 32px;
    line-height: 39px;
  }
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 1.65em;
    line-height: 1.4em;
    letter-spacing: 0.04em;
  }
}

h3 {
  letter-spacing: 0.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
}

h4 {
  margin-bottom: 10px;
  color: #222;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
}

h4 .h4__inner-text {
  position: relative;
  z-index: 20;
}

h4:after {
  position: relative;
  top: -10px;
  left: -15px;
  z-index: 10;
  display: block;
  height: 15px;
  content: ' ';
  opacity: .5;
}

@media screen and (max-width: 768px) {
  h4:after {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  h4 {
    margin-bottom: 1.25em;
    font-size: 0.9em;
    line-height: 1.2em;
  }
}

h4.h4--mystic {
  color: #222;
}

h4.h4--mystic:after {
  background: #edf2f4;
}

h4.h4--steel-blue {
  color: #fff;
}

h4.h4--steel-blue:after {
  background: #00577b;
}

h5 {
  letter-spacing: 0.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

h6 {
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

p {
  margin-bottom: 10px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 0.9em;
    line-height: 1.6em;
  }
}

section {
  position: relative;
  padding: 60px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  section {
    padding: 3em 0;
  }
}

@media screen and (max-width: 420px) {
  section {
    padding: 2.5em 0;
  }
}

table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

table td {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

table td:last-child {
  border-right: none;
}

input,
input[type="text"] {
  display: inline-block;
  padding: 8px 12px;
  border: none;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
}

input:focus,
input[type="text"]:focus {
  outline: none;
}

input[type="submit"] {
  display: block;
  margin: 0 auto;
  padding: 8px 12px;
  background: #7fabbd;
  color: #fff;
  border: 2px solid #3f819c;
  border-radius: 2px;
  appearance: none;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  cursor: pointer;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  transition: background 0.3s, border 0.3s;
}

input[type="submit"]:hover {
  background: #3f819c;
}

textarea {
  padding: 8px 12px;
  border: none;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  resize: none;
}

textarea:focus {
  outline: none;
}

select {
  display: block;
  padding: 10px 12px;
  appearance: none;
  outline: none;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  letter-spacing: 1.5px;
  cursor: pointer;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-indent: 0.01px;
  text-overflow: '';
}

@media screen and (max-width: 768px) {
  select {
    width: 100%;
  }
}

button {
  background: none;
  border: none;
  appearance: none;
  color: #3f819c;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  transition: color 0.2s;
}

button:hover {
  color: #ff494f;
}

button:focus {
  outline: none;
}

.select-wrap {
  position: relative;
  padding: 0;
}

.select-wrap::after {
  position: absolute;
  top: 11px;
  right: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  pointer-events: none;
  font-family: FontAwesome;
  font-size: 16px;
  content: '\f107';
}

.fa.fa-angle-up,
.fa.fa-angle-down {
  font-size: 16px;
  line-height: inherit;
}

body.no-scroll {
  overflow-y: hidden;
}

body.mobile-menu-open .header .container > .icon--hamburger {
  opacity: 0;
}

body.mobile-menu-open .menu-wrap {
  z-index: 80;
}

body.logged-in.admin-bar #wpadminbar {
  display: none;
}

.clearfix {
  clear: both;
}

.weight-book {
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  box-sizing: border-box;
}

@media screen and (max-width: 1280px) {
  .container {
    max-width: 800px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 0 1.5em;
  }
}

.container.container--small {
  max-width: 960px;
}

@media screen and (max-width: 1280px) {
  .container.container--small {
    max-width: 800px;
  }
}

@media screen and (max-width: 1024px) {
  .container.container--small {
    max-width: 720px;
  }
}

@media screen and (max-width: 768px) {
  .container.container--small {
    max-width: 100%;
    padding: 0 2em;
  }
}

.divider {
  display: block;
  width: 50px;
  height: 1px;
  background: #222;
}

.divider.divider--large {
  width: 100px;
  height: 2px;
}

.link,
.link__standard,
.link__action {
  cursor: pointer;
  transition: opacity 0.2s;
}

.link:hover,
.link__standard:hover,
.link__action:hover {
  opacity: 0.6;
}

.link__standard .link-text {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.link__standard.link--sunset-orange {
  color: #ff494f;
}

.link__action {
  display: inline-block;
  color: #00577b;
}

.link__action .link-text {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.link__action .fa {
  position: relative;
  left: 2px;
  font-size: 16px;
}

.link__action--white {
  color: #fff;
}

.link__btn {
  display: inline-block;
  padding: 12px 16px;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  transition: background 0.2s, color 0.2s;
}

.link__btn.link--sunset-orange {
  border-color: #ff494f;
  color: #ff494f;
}

.link__btn.link--sunset-orange:hover {
  background: #ff494f;
  color: #fff;
}

.btn {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: pointer;
}

.btn:focus {
  outline: none;
}

.btn:hover .btn__inner-fill:after, .btn:active .btn__inner-fill:after {
  width: 100%;
}

.btn .btn__inner-fill {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 2px;
  transition: border-color 0.3s;
}

.btn .btn__inner-fill:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  content: '';
  transition: width 0.3s;
}

.btn .btn__inner-fill:hover, .btn .btn__inner-fill:active {
  transition: border-color 0s;
}

.btn .btn__text {
  position: relative;
  z-index: 30;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  transition: color 0.3s;
}

.btn .btn__shadow {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 2px;
  transition: background 0.3s, border-color 0.3s;
}

.btn.btn--neptune .btn__inner-fill {
  background: #7fabbd;
  border-color: #7fabbd;
}

.btn.btn--neptune .btn__text {
  color: #fff;
}

.btn.btn--neptune .btn__shadow {
  background: #3f819c;
  border-color: #3f819c;
}

.btn.btn--neptune:hover .btn__inner-fill, .btn.btn--neptune:active .btn__inner-fill {
  border-color: #3f819c;
}

.btn.btn--neptune:hover .btn__inner-fill:after, .btn.btn--neptune:active .btn__inner-fill:after {
  background: #3f819c;
}

.btn.btn--neptune:hover .btn__shadow, .btn.btn--neptune:active .btn__shadow {
  background: #7fabbd;
  border-color: #7fabbd;
}

.btn.btn--blue .btn__inner-fill {
  background: #3f819c;
  border-color: #3f819c;
}

.btn.btn--blue .btn__text {
  color: #fff;
}

.btn.btn--blue .btn__shadow {
  background: #00577b;
  border-color: #00577b;
}

.btn.btn--blue:hover .btn__inner-fill, .btn.btn--blue:active .btn__inner-fill {
  border-color: #00577b;
}

.btn.btn--blue:hover .btn__inner-fill:after, .btn.btn--blue:active .btn__inner-fill:after {
  background: #00577b;
}

.btn.btn--blue:hover .btn__shadow, .btn.btn--blue:active .btn__shadow {
  background: #3f819c;
  border-color: #3f819c;
}

.btn.btn--dark-blue .btn__inner-fill {
  background: #00577b;
  border-color: #00577b;
}

.btn.btn--dark-blue .btn__text {
  color: #fff;
}

.btn.btn--dark-blue .btn__shadow {
  background: #3f819c;
  border-color: #3f819c;
}

.btn.btn--dark-blue:hover .btn__text, .btn.btn--dark-blue:active .btn__text {
  color: #00577b;
}

.btn.btn--dark-blue:hover .btn__inner-fill:after, .btn.btn--dark-blue:active .btn__inner-fill:after {
  background: #fff;
}

.btn.btn--dark-blue:hover .btn__shadow, .btn.btn--dark-blue:active .btn__shadow {
  background: #fff;
}

.btn.btn--white .btn__inner-fill {
  background: #fff;
  border-color: #00577b;
}

.btn.btn--white .btn__text {
  color: #00577b;
}

.btn.btn--white .btn__shadow {
  background: transparent;
  border-color: #3f819c;
}

.btn.btn--white:hover .btn__text, .btn.btn--white:active .btn__text {
  color: #fff;
}

.btn.btn--white:hover .btn__inner-fill, .btn.btn--white:active .btn__inner-fill {
  border-color: #3f819c;
}

.btn.btn--white:hover .btn__inner-fill:after, .btn.btn--white:active .btn__inner-fill:after {
  background: #3f819c;
}

.btn.btn--white:hover .btn__shadow, .btn.btn--white:active .btn__shadow {
  background: #00577b;
  border-color: #00577b;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 68px;
  background: rgba(255, 255, 255, 0.99);
  box-shadow: 0 0 2px 2px rgba(200, 200, 200, 0.3);
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 12px 0;
    backface-visibility: hidden;
  }
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .header .container {
    padding: 0 20px;
  }
}

.header h1.site-title {
  float: left;
  font-size: 20px;
  line-height: 22px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .header h1.site-title {
    position: relative;
    width: auto;
    padding-top: 0;
  }
}

.header h1.site-title > a.logo {
  display: block;
  max-width: 180px;
  color: #333;
  transition: color 0.2s;
}

.header h1.site-title > a.logo .logo__top {
  font-weight: 500;
}

.header h1.site-title > a.logo .logo__bottom {
  font-weight: 400;
}

.header h1.site-title > a.logo:hover {
  color: #ff494f;
}

.header .container > .icon--hamburger {
  display: none;
  cursor: pointer;
}

.header .container > .icon--hamburger .icon__bar,
.header .container > .icon--hamburger .icon__bar:before,
.header .container > .icon--hamburger .icon__bar:after {
  color: #222;
}

@media screen and (max-width: 768px) {
  .header .container > .icon--hamburger {
    display: block;
    opacity: 1;
    transition: opacity 0.5s;
  }
}

@media screen and (max-width: 768px) {
  .menu-wrap {
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 20;
    display: block;
    overflow-y: scroll;
    padding: 0;
    background: rgba(255, 255, 255, 0.98);
    opacity: 0;
    width: 100%;
    height: 100vh;
    transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
  }
}

.menu-wrap .locations__mobile-menu {
  display: none;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .menu-wrap .locations__mobile-menu {
    display: block;
  }
}

.menu-wrap .icon--close {
  position: absolute;
  top: -100%;
  right: 10px;
  z-index: 80;
  padding: 1em;
  transition: top 0.5s ease-in-out;
}

.menu-wrap.is-open {
  top: 0;
  opacity: 1;
}

.menu-wrap.is-open .icon--close {
  top: 10px;
}

.menu-wrap.is-open .icon--close:before, .menu-wrap.is-open .icon--close:after {
  opacity: 1;
}

.menu {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px 0 0;
  color: #333;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .menu {
    display: block;
    width: 100%;
    padding-right: 0;
    padding-top: 5em;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .menu li:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.menu-item {
  position: relative;
  display: inline-block;
  padding: 20px 1.5em;
}

@media screen and (max-width: 1024px) {
  .menu-item {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}

@media screen and (max-width: 768px) {
  .menu-item {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
  }
}

.menu-item > a {
  display: block;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  transition: color 0.2s;
}

@media screen and (max-width: 768px) {
  .menu-item > a {
    display: block;
    padding: 15px 0;
    color: #222;
    transition: background 0.2s, color 0.2s;
  }
}

.menu-item .fa {
  position: relative;
  left: 5px;
  display: none;
  transition: transform 0.2s, color 0.2s;
}

@media screen and (max-width: 768px) {
  .menu-item .fa {
    position: absolute;
    top: 15px;
    left: inherit;
    right: 1.5em;
    font-size: 20px;
  }
}

.menu-item.menu-item-has-children .fa {
  display: inline-block;
}

.menu-item > a:hover,
.menu-item.is-open > a,
.menu-item.is-active > a {
  color: #ff494f;
}

.menu-item > a:hover .fa,
.menu-item.is-open > a .fa,
.menu-item.is-active > a .fa {
  color: #ff494f;
}

@media screen and (max-width: 768px) {
  .menu-item > a:hover,
  .menu-item.is-open > a,
  .menu-item.is-active > a {
    background: #fff;
  }
}

.menu-item.is-open .fa {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.menu-item.is-open ul.sub-menu {
  max-height: 1000px;
  opacity: 1;
}

ul.sub-menu {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 60;
  overflow: hidden;
  max-height: 0;
  background: #ff494f;
  text-align: left;
  opacity: 0;
  transition: max-height 0.3s, opacity 0.3s;
}

@media screen and (max-width: 768px) {
  ul.sub-menu {
    position: relative;
    top: 0;
    right: inherit;
    z-index: auto;
    width: 100%;
    text-align: center;
    transition: max-height 0.5s;
  }
}

ul.sub-menu:before {
  position: absolute;
  top: -5px;
  right: 17px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ff494f;
  content: '';
}

@media screen and (max-width: 768px) {
  ul.sub-menu:before {
    display: none;
  }
}

ul.sub-menu .sub-menu-item {
  display: block;
  margin: 5px;
  padding: 10px 12px;
  transition: background 0.2s;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  ul.sub-menu .sub-menu-item {
    padding: 0;
    margin: 0;
  }
  ul.sub-menu .sub-menu-item > .menu-link {
    padding: 0;
  }
  ul.sub-menu .sub-menu-item > .menu-link.sub-menu-link {
    padding: 15px 0;
  }
}

ul.sub-menu .sub-menu-item .sub-menu-link {
  color: #fff;
  white-space: nowrap;
}

ul.sub-menu .sub-menu-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

ul.sub-menu .sub-menu-item .fa {
  display: none;
}

@media screen and (max-width: 768px) {
  ul.sub-menu .sub-menu-item > a:hover {
    background: transparent;
  }
}

span.icon--hamburger {
  position: relative;
  z-index: 70;
  float: left;
  display: inline-block;
  width: 20px;
  height: 38px;
  margin-right: 10px;
}

span.icon--hamburger .icon__bar,
span.icon--hamburger .icon__bar:before,
span.icon--hamburger .icon__bar:after {
  position: absolute;
  left: 0;
  width: 20px;
  height: 2px;
  background: #222;
}

span.icon--hamburger .icon__bar {
  top: 17px;
}

span.icon--hamburger .icon__bar:before {
  top: -6px;
  width: 20px;
  content: '';
}

span.icon--hamburger .icon__bar:after {
  top: 6px;
  width: 20px;
  content: '';
}

.icon--close {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.icon--close:before, .icon--close:after {
  position: absolute;
  top: 25px;
  left: 10px;
  width: 30px;
  height: 2px;
  background: #222;
  transition: opacity 0.2s;
  content: '';
}

.icon--close:before {
  transform: rotate(45deg);
}

.icon--close:after {
  transform: rotate(-45deg);
}

.icon--close:hover:before, .icon--close:hover:after {
  opacity: 0.7;
}

.locations__menu {
  display: table-cell;
  overflow: hidden;
  padding: 15px;
  width: 200px;
  background: #00577b;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 0.2s;
}

@media screen and (max-width: 1024px) {
  .locations__menu {
    width: 50px;
  }
}

@media screen and (max-width: 768px) {
  .locations__menu {
    display: none;
  }
}

.locations__menu:hover {
  background: rgba(0, 87, 123, 0.7);
}

.locations__menu h3 {
  float: left;
  display: inline-block;
  padding-top: 9px;
  margin-left: 5px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

@media screen and (max-width: 1024px) {
  .locations__menu h3 {
    display: none;
  }
}

.locations__menu span.icon--hamburger {
  display: block;
}

.locations__menu span.icon--hamburger .icon__bar,
.locations__menu span.icon--hamburger .icon__bar:before,
.locations__menu span.icon--hamburger .icon__bar:after {
  background: #fff;
}

.takeover {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  overflow-y: scroll;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.98);
  color: #222;
  text-align: center;
  opacity: 0;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.takeover .icon--close {
  position: fixed;
  top: 10px;
  right: 20px;
  display: block;
  padding: 1em;
}

.takeover h4.h4--mystic {
  color: #fff;
}

.takeover h4.h4--mystic:after {
  left: 0;
  margin: 0 auto;
}

.takeover.is-open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: transform 0.4s, opacity 0.4s;
}

.locations__takeover-wrap {
  padding: 60px 0;
}

.locations__takeover {
  padding: 40px;
}

.locations__takeover h3 {
  margin-bottom: 40px;
  color: #3f819c;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.locations__takeover .locations__item {
  margin: 1.25em 0;
}

.locations__takeover .locations__item > a {
  display: inline-block;
  padding: 0;
  color: #222;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  transition: color 0.2s;
}

.locations__takeover .locations__item > a:hover {
  color: #ff494f;
}

.locations__takeover .locations__item:last-child:before {
  display: block;
  width: 50px;
  height: 1px;
  margin: 1.75em auto;
  background: #222;
  content: '';
}

h2.region__title {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 40px;
  letter-spacing: 1px;
  white-space: nowrap;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 500;
  font-size: 7em;
  line-height: normal;
}

@media screen and (max-width: 1024px) {
  h2.region__title {
    font-size: 6em;
  }
}

@media screen and (max-width: 768px) {
  h2.region__title {
    left: inherit;
    min-width: inherit;
    justify-content: flex-start;
    font-size: 3em;
  }
}

h2.region__title span {
  padding: 0 .3em;
  flex-shrink: 0;
}

@media screen and (max-width: 768px) {
  h2.region__title span {
    text-align: left;
  }
}

h2.region__title:before {
  flex-shrink: 0;
  color: #ff494f;
  opacity: .7;
  content: attr(data-text);
}

@media screen and (max-width: 768px) {
  h2.region__title:before {
    display: none;
  }
}

h2.region__title:after {
  flex-shrink: 0;
  color: #ff494f;
  opacity: .7;
  content: attr(data-text);
}

.page__contact {
  text-align: center;
}

.page__contact .container {
  max-width: 920px;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .page__contact .container {
    max-width: 100%;
    padding: 0 1.5em;
  }
}

.page__contact h5 {
  margin-bottom: 1.5em;
}

.page__contact .contact__info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 3em auto 0;
}

.page__contact .contact__info .contact__info-wrap {
  margin: 0 2em;
}

.page__contact .contact__info h5 {
  margin-bottom: .75em;
}

.page__contact .contact__info a {
  color: #ff494f;
  transition: opacity 0.2s;
}

.page__contact .contact__info a:hover {
  opacity: 0.7;
}

.page__contact .contact__info p {
  margin-bottom: .4em;
}

.contact__form li {
  list-style-type: none;
}

.contact__form .flex__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 2em;
}

@media screen and (max-width: 768px) {
  .contact__form .flex__row {
    flex-flow: row wrap;
    margin-bottom: 0;
  }
}

.contact__form .input__wrap,
.contact__form .wpcf7-form-control-wrap {
  flex-grow: 1;
  margin-right: 2em;
}

@media screen and (max-width: 768px) {
  .contact__form .input__wrap,
  .contact__form .wpcf7-form-control-wrap {
    width: 100%;
    margin: 0 0 1.5em;
  }
}

.contact__form .input__wrap:last-child,
.contact__form .wpcf7-form-control-wrap:last-child {
  margin-right: 0;
}

.contact__form .input__wrap input[type="text"],
.contact__form .input__wrap input[type="email"],
.contact__form .wpcf7-form-control-wrap input[type="text"],
.contact__form .wpcf7-form-control-wrap input[type="email"] {
  width: 100%;
  border: 1px solid #9BA0A3;
}

.contact__form .input__wrap textarea,
.contact__form .wpcf7-form-control-wrap textarea {
  width: 100%;
  height: 200px;
  background: #fff;
  border: 1px solid #9BA0A3;
}

.content {
  padding-top: 68px;
}

.vertical-nav {
  position: fixed;
  top: 50%;
  left: 20px;
  z-index: 40;
  width: 100px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 1024px) {
  .vertical-nav {
    display: none;
  }
}

.vertical-nav__item {
  margin: 0 0 10px;
}

.vertical-nav__link {
  padding: .4em 0;
}

.vertical-nav__link span {
  display: inline-block;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
}

.vertical-nav__link .vertical-nav__dot {
  position: relative;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid #ff494f;
  transition: transform 0.2s, background-color 0.5s;
  -webkit-backface-visibility: hidden;
}

.vertical-nav__link .vertical-nav__link-text {
  position: relative;
  top: -3px;
  color: #222;
  opacity: 0;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  transition: transform 0.2s, opacity 0.2s, color 0.2s;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.vertical-nav__link:hover span {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.vertical-nav__link:hover .vertical-nav__link-text {
  opacity: 1;
}

.vertical-nav__link.is-selected .vertical-nav__dot {
  background: #ff494f;
}

.vertical-nav__link.is-dark .vertical-nav__link-text {
  color: #fff;
}

.vertical-nav__item--contact .vertical-nav__dot {
  left: -2px;
  width: 21px;
  height: 17px;
  margin-right: 0;
  background: url("../img/icon-contact.png") no-repeat;
  border: none;
  border-radius: none;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.beam-me-up__wrap {
  position: absolute;
  right: 20px;
  bottom: 40px;
  color: #222;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .beam-me-up__wrap {
    display: none;
  }
}

.beam-me-up__wrap span {
  display: inline-block;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
}

.beam-me-up__wrap span.fa {
  font-size: 36px;
  padding-right: 6px;
  transition: transform 0.2s;
}

.beam-me-up__wrap span.beam-me-up__text {
  position: relative;
  top: -3px;
  opacity: 0;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
  transition: transform 0.2s, opacity 0.2s;
}

.beam-me-up__wrap:hover span {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.footer {
  display: block;
  width: 100%;
}

.footer a {
  transition: opacity 0.2s;
}

.footer a:hover {
  opacity: 0.6;
}

.footer__top {
  padding: 40px 0 30px;
}

.footer__bottom {
  overflow: hidden;
  padding: 20px 0;
  background: #00577b;
  color: #fff;
  font-weight: 300;
}

.footer__bottom .container > span {
  float: left;
  width: 33.3333333%;
}

@media screen and (max-width: 768px) {
  .footer__bottom .container > span {
    display: block;
    width: 100%;
    padding: 8px 10px;
    text-align: center;
  }
}

.footer__aside {
  padding: 0 0 20px;
  width: 100%;
  text-align: center;
}

.footer__aside p {
  letter-spacing: 0.5px;
  font-size: 20px;
  line-height: 25px;
}

@media screen and (max-width: 768px) {
  .footer__aside p {
    font-size: 0.9em;
    line-height: 1.2em;
  }
}

.footer__aside .contact-us {
  padding-top: .5em;
}

.footer__aside .footer__label {
  display: block;
  letter-spacing: 0.5px;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

.footer__aside input[type="text"] {
  margin-top: 5px;
  background: #edf2f4;
  width: 320px;
}

.footer__aside .link__action {
  margin-left: 15px;
}

.footer .table--contact {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer .table--contact tr {
    display: block;
  }
}

.footer .table--contact td {
  padding: 5px 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .footer .table--contact td {
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
  }
}

@media screen and (max-width: 768px) {
  .footer .table--contact td:last-child {
    border-bottom: none;
  }
}

.section__intro {
  background: url("../img/bg-dot.png") repeat;
  background-position: -4px -4px;
  text-align: center;
}

.section__intro h1 {
  margin-bottom: .25em;
}

.section__intro > .container p:first-child {
  margin-bottom: 0.5em;
}

.section__intro p {
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
}

@media screen and (max-width: 1024px) {
  .section__intro p {
    font-size: 24px;
    line-height: 36px;
  }
}

@media screen and (max-width: 768px) {
  .section__intro p {
    font-size: 1.5em;
    line-height: 1.5em;
  }
}

.send-email__wrap {
  display: block;
  margin-top: 40px;
  text-align: center;
}

.send-email__wrap .link--email {
  display: inline-block;
  color: #ff494f;
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

section.page__404 {
  text-align: center;
}
